<template>
  <div class="loading-page flex-center" v-if="show">
    <p class="px-2">Loading.......{{ count }}%</p>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";
import {emitter} from "@/utils/utils";

export default {
  name: "Loading",
  props: {
    time: Number,
    autoClose: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const show = ref(true);
    const count = ref(0);
    const loadingTimer = ref(null);
    const time = props.time || 20;

    onMounted(() => {
      handleLoadingCount();
      emitter.on("close-loading", () => { 
        show.value = false;
      }); 
    });

    onUnmounted(() => {
      handleClearTimer();
    });

    function handleLoadingCount() {
      loadingTimer.value = setInterval(() => {
        count.value++;
        if (count.value >= 100) {
          handleClearTimer();
          count.value = 100;
          if(props.autoClose) show.value = false;
        }
      }, time);
    }

    function handleClearTimer() {
      if (loadingTimer.value) {
        clearInterval(loadingTimer.value);
      }
    }

    return {
      show,
      count,
      loadingTimer
    };
  },
};
</script>

<style lang="scss" scoped>
.loading-page {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff;
  z-index: 1000;

  p {
    background-color: #000000;
    font-size: 2vw;
    color: #ffffff;
    font-family: "Podkova", "Noto Sans TC", Arial, Helvetica, sans-serif;
  }
}
</style>
