import request from "@/utils/request";

/**
 * Get all artists
 */
export function getArtists() {
  return request({
    url: "api/v1/artists",
    method: "GET",
  });
}

/**
 * Get one advisor
 * @param {string} artist_id
 */
export function getArtist(artist_id) {
  return request({
    url: `api/v1/artists/${artist_id}`,
    method: "GET",
  });
}