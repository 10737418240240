<template lang="pug">
.artistModal__collapse
  .artistModal__collapse__header(@click="isCollapseOpen = !isCollapseOpen; collapse.toggle();")
    img.artistModal__collapse__icon(:src="require(`@/assets/images/modal-arrow.svg`)" :class="{'artistModal__collapse__icon--collapsed':!isCollapseOpen}")
    .artistModal__collapse__title
      slot(name="title")
  .collapse(ref="collapse")
    .artistModal__collapse__content
      slot(name="content")
  br
</template>

<script>
import { Collapse } from "bootstrap";

export default {
  name: "ArtistCollapse",
  components: {},
  data() {
    return {
      isCollapseOpen: true,
    };
  },
  mounted() {
    this.collapse = new Collapse(this.$refs.collapse);
  },
  setup() {},
};
</script>

<style lang="sass" scoped>
*
  transition: 0.35s ease-out

.artistModal__collapse
  &__header
    cursor: pointer
    display: flex
    flex-direction: row

  &__icon
    padding: 0 9px

    &--collapsed
      transform: rotate(-90deg)

  &__title
    font-weight: 500

  &__content
    margin: 12px 0
    height: auto
    opacity: 1
    transition: transform 2s ease-in
    +rwd-md
      margin: 16px
</style>
