import * as PIXI from "pixi.js";
import {
    gsap,
    Power2
} from "gsap";

export class Work extends PIXI.Sprite {
    constructor(vueComponent, position, texture, slug, baseWidth) {
        super(texture);
        this.slug = slug
        this.vueComponent = vueComponent
        this.container = vueComponent.viewport
        this.baseWidth = baseWidth*0.95
        // this.baseWidth = 60 + 30 * Math.random();
        this.baseScale = this.baseWidth / texture.orig.width;
        this.interactive = true;
        this.position.set(position.x, position.y);
        this.anchor.set(0.5, 0.5);
        this.scale.set(0);
        this.container.addChildAt(this, 0);
        this.show()

        this.buttonMode = true;
        this.defaultCursor = 'pointer';
        this.time = null
        this.on("click", this.clickWork);
        this.on("pointerdown", this.snapStart);
        this.on("pointerup", this.snapEnd);
    }

    snapStart() {
        this.time = new Date()
    }

    snapEnd() {
        const timeDiff = new Date() - this.time
        if (timeDiff < 100) {
            this.clickWork()
        }
        this.time = null
    }

    show() {
        gsap.to(this.scale, 0.6, {
            x: this.baseScale,
            y: this.baseScale,
            ease: Power2.easeInOut,
            delay: 1.2 * Math.random()
        });
    }

    clickWork() {
        this.vueComponent.toArtWork(this.slug)
    }

    mouseover = function () {
        this.vueComponent.isDetectingMove = true
        this.scaleWork()
    };
    scaleWork() {
        this.container.children.forEach((child, index) => {
            this.container.setChildIndex(child, index)
            if(child.scale.x !== child.baseScale && child !== this){
                gsap.to(child.scale, 0.6, {
                    x: child.baseScale,
                    y: child.baseScale,
                    ease: Power2.easeInOut
                });
            }
        })
        gsap.to(this.scale, 1, {
            x: this.baseScale * 1.5,
            y: this.baseScale * 1.5,
            ease: 'easeIn'
        });
        this.container.setChildIndex(this, this.container.children.length - 1)
    }
    mouseout = function () {
        gsap.to(this.scale, 1, {
            x: this.baseScale,
            y: this.baseScale,
            ease: 'easeOut'
        });
        // this.container.setChildIndex(this, 0)
    };
}