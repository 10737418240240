<template>
  <div class="works" ref="works">
    <!-- <p class="px-2"> works </p> -->
  </div>
</template>

<script>
import * as PIXI from "pixi.js";
import { Work } from "./work";
import { Viewport } from "pixi-viewport";
import { getDistance, lerp } from "@/utils/utils";
import {emitter} from "@/utils/utils";


export default {
  name: "Works",
  props: {
    works: Array,
  },
  data() {
    return {
      app: null,
      viewport: null,
      isMobile: false,
      isDetectingMove: true,
      imgBaseWidth: 200,
      worldWidth: 0,
      worldHeigh: 0,
      currentPos: {
        x: 0,
        y: 0,
      },
      targetPos: {
        x: 0,
        y: 0,
      },
    };
  },
  setup() {
    // document.body.appendChild(app.view)
  },
  methods: {
    updateTargetPos(e) {
      if (!this.isDetectingMove) return;
      this.targetPos.x = e.clientX;
      this.targetPos.y = e.clientY;
    },
    updateCenter() {
      this.currentPos.x = lerp(this.currentPos.x, this.targetPos.x, 0.005);
      this.currentPos.y = lerp(this.currentPos.y, this.targetPos.y, 0.005);

      this.viewport.moveCenter(this.currentPos.x, this.currentPos.y);
    },
    initViewport() {
      const WORLD_WIDTH = this.isMobile
        ? window.innerWidth * 4.5
        : Math.min(window.innerWidth * 1.6, 2000);
      const WORLD_HEIGHT = this.isMobile
        ? window.innerHeight * 2.5
        : window.innerHeight * 1.2;
      this.worldWidth = WORLD_WIDTH;
      this.worldHeigh = WORLD_HEIGHT;

      const app = new PIXI.Application({
        width: WORLD_WIDTH,
        height: WORLD_HEIGHT,
        backgroundAlpha: 0,
      });
      this.app = app;
      this.$refs.works.appendChild(app.view);

      // create viewport
      const viewport = new Viewport({
        // screenWidth: window.innerWidth,
        // screenHeight: window.innerHeight,
        worldWidth: WORLD_WIDTH,
        worldHeight: WORLD_HEIGHT,
        interaction: app.renderer.plugins.interaction, // the interaction module is important for wheel to work properly when renderer.view is placed or scaled
      });

      this.viewport = viewport;
      if (!this.isMobile) {
        viewport.clamp({
          left: -WORLD_WIDTH * 0.5,
          right: WORLD_WIDTH * 0.5,
          top: -WORLD_HEIGHT * 0.5,
          bottom: WORLD_HEIGHT * 0.5,
          direction: "all", // (all, x, or y) using clamps of [0, viewport.worldWidth / viewport.worldHeight]; replaces left / right / top / bottom if set
          underflow: "center", // where to place world if too small for screen (e.g., top - right, center, none, bottomleft)
        });
      } else {
        viewport.clamp({
          left: -WORLD_WIDTH * 2,
          right: WORLD_WIDTH * 2,
          top: -WORLD_HEIGHT * 2,
          bottom: WORLD_HEIGHT * 2,
          direction: "all", // (all, x, or y) using clamps of [0, viewport.worldWidth / viewport.worldHeight]; replaces left / right / top / bottom if set
          underflow: "center", // where to place world if too small for screen (e.g., top - right, center, none, bottomleft)
        });
      }
      viewport.clampZoom({
        minWidth: null, // minimum width
        minHeight: null, // minimum height
        maxWidth: null, // maximum width
        maxHeight: null, // maximum height
        minScale: this.isMobile ? 0.5 : 1.5, // minimum scale
        maxScale: this.isMobile ? 3 : 1.5, // minimum scale
      });
      // }
      // add the viewport to the stage
      app.stage.addChild(viewport);

      // activate plugins
      viewport.drag().pinch().wheel().decelerate();

      // function border(viewport) {
      //   const line = viewport.addChild(new PIXI.Graphics());
      //   line
      //     .lineStyle(10, 0xff0000)
      //     .drawRect(0, 0, this.worldWidth, this.worldHeight);
      // }

      // border(viewport, 10);
      viewport.fit();
      viewport.moveCenter(WORLD_WIDTH / 2, WORLD_HEIGHT / 2);
      // viewport.moveCenter(WORLD_WIDTH*(0.5-0.1), WORLD_HEIGHT*(0.5-0.2));
    },
    async addWork(vueComponent, position, texture, slug) {
      new Work(vueComponent, position, texture, slug, this.imgBaseWidth);
    },
    toArtWork(slug) {
      this.$store.dispatch("updateCurrentWorkType", "fmaf");
      this.$router.push({ path: `/artworks/${slug}` });
    },
    getRandomPos() {
      return {
        x: this.isMobile
          ? window.innerWidth * (Math.random() * 2)
          : window.innerWidth * (0.2 + Math.random() * 0.4),
        y: this.isMobile
          ? window.innerHeight * (Math.random() * 1.5)
          : window.innerHeight * (0.2 + Math.random() * 0.6),
      };
    },
    getSortedPostions(textures) {
      let itemCountPerRow = 4;
      if(textures.length < 10) itemCountPerRow = 3;
      if(textures.length < 6) itemCountPerRow = 2;
      
      for (const [index, texture] of textures.entries()) {
        let rowIndex = Math.floor(index / itemCountPerRow);
        let offsetX = 1;

        // 左邊 or 右邊
        offsetX *= rowIndex % 2 === 0 ? -1 : 1;

        // 相鄰的兩項移動相同的距離（正負不同）
        offsetX *= Math.ceil(rowIndex / 2);
        offsetX *= this.imgBaseWidth;

        // Add offset Y
        if (rowIndex !== 0)
          texture.pos.y += rowIndex * 5 * (rowIndex % 2 === 0 ? -1 : 1);

        const currentDividedIndex = index % itemCountPerRow;
        texture.pos.x += offsetX;
        if (currentDividedIndex == 0) {
          texture.pos.x += 0;
          texture.pos.y += 0;
        } else {
          if (currentDividedIndex % 2 !== 0) {
            texture.pos.y -=
              textures[rowIndex * itemCountPerRow + 0].resizedH / 2;
            if (currentDividedIndex === 1) {
              texture.pos.y -=
                textures[rowIndex * itemCountPerRow + 1].resizedH / 2;
            }
            if (currentDividedIndex === 3) {
              texture.pos.y -=
                textures[rowIndex * itemCountPerRow + 1].resizedH;
              texture.pos.y -=
                textures[rowIndex * itemCountPerRow + 3].resizedH / 2;
            }
          }
          if (currentDividedIndex % 2 === 0) {
            texture.pos.y +=
              textures[rowIndex * itemCountPerRow + 0].resizedH / 2;

            if (currentDividedIndex === 2) {
              texture.pos.y +=
                textures[rowIndex * itemCountPerRow + 2].resizedH / 2;
            }
            if (currentDividedIndex === 4) {
              texture.pos.y +=
                textures[rowIndex * itemCountPerRow + 2].resizedH;
              texture.pos.y +=
                textures[rowIndex * itemCountPerRow + 4].resizedH / 2;
            }
          }
        }
      }
      return textures;
    },
    checkAnyClosePos(list, targetPos) {
      const closePosEl = list.find((pos) => getDistance(pos, targetPos) < 250);
      return closePosEl ? true : false;
    },
    async resetWorks() {
      this.viewport.removeChildren();
      const textures = [];
      // const tempData = this.works.slice(0, 15);
      const tempData = this.works.filter((w) => w.photo_1);
      // get all textures and texture size
      const ww = this.worldWidth;
      const wh = this.worldHeigh;
      for (const [index, work] of tempData.entries()) {
        const texture = await PIXI.Texture.fromURL(
          this.$getPhotoUrl(work.photo_1)
        );
        const resizeRatio = this.imgBaseWidth / texture.orig.width;

        textures.push({
          index: index,
          texture,
          width: texture.orig.width,
          height: texture.orig.height,
          resizedW: texture.orig.width * resizeRatio,
          resizedH: texture.orig.height * resizeRatio,
          pos: {
            x: ww * (0.5 + (this.isMobile ? -0.1 : -0.1)),
            y: wh * (0.5 + 0.05),
          },
        });
      }
      // console.log(textures);

      // get all textures sizes
      const positions = this.getSortedPostions(textures);

      //
      tempData.forEach(async (w, index) => {
        let position = positions[index].pos;
        // let position = this.getRandomPos();

        if (w.photo_1) {
          const imgUrl = this.$getPhotoUrl(w.photo_1);
          const url = imgUrl;
          const texture = await PIXI.Texture.fromURL(url);
          this.addWork(this, position, texture, w.slug);
        }
      });
      emitter.emit("close-loading")
    },
    moveToArtWork(slug) {
      this.isDetectingMove = false;
      const work = this.viewport.children.find((c) => c.slug === slug);
      this.targetPos.x = work.position._x;
      this.targetPos.y = work.position._y;
      work.scaleWork();
    },
    resize() {
      const w = window.innerWidth;
      const h = window.innerHeight;

      this.app.renderer.resize(w, h);
      this.viewport.resize(w, h);
      // this.viewport.ensureVisible(w/2, h/2, w, h, true)
      this.resetWorks();
    },
  },
  mounted() {
    if (window.innerWidth < 1025) {
      this.isMobile = true;
    }
    this.initViewport();
    this.resetWorks();

    if (!this.isMobile) {
      document.addEventListener("mousemove", this.updateTargetPos, true);
      setInterval(this.updateCenter, 5);
    }
    document.addEventListener("resize", this.resize, true);
    // window.requestAnimationFrame(this.updateCenter);
  },
  beforeUnmount() {
    if (!this.isMobile)
      document.removeEventListener("mousemove", this.updateTargetPos, true);
    document.removeEventListener("resize", this.resize, true);
  },
};
</script>

<style lang="scss" scoped>
.works {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 0;

  canvas {
    width: 100vw;
    height: 100vh;
  }
}
</style>
