<template lang="pug">
.sidebar__collapse(:class="{'home': location === 'home'}")
  .sidebar__collapse__header(:class="{'sidebar__collapse__header--acitve': !isClose}")
    img.sidebar__collapse__icon(:src="arrowImage" :class="{'sidebar__collapse__icon--collapsed':isClose,'visiable':!children}")
    .sidebar__collapse__title
      slot(name="title")
  .collapse(ref="collapse" v-if="children")
    .sidebar__collapse__content
      slot(name="content")
</template>

<script>
import { Collapse } from "bootstrap";

export default {
  name: "SidebarCollapse",
  components: {},
  data() {
    return {
      isCollapseOpen: true,
    };
  },
  props: {
    children: Boolean,
    location: {
      type: String,
      default: 'artwork'
    },
    isClose: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    if (this.children) {
      this.collapse = new Collapse(this.$refs.collapse, { toggle: false });
      this.collapse.hide();
    }
  },
  watch: {
    isClose(val) {
      if (this.children) {
        if (val) {
          this.collapse.hide();
        } else {
          this.collapse.show();
        }
      }
    },
  },
  computed: {
    arrowImage(){
      return this.location === 'home' ? require('@/assets/images/modal-arrow-white.svg') : require('@/assets/images/modal-arrow.svg');
    }
  }
};
</script>

<style lang="sass" scoped>
*
  transition: 0.35s ease-out

.sidebar__collapse
  &__header
    padding: 18px 0
    cursor: pointer
    display: flex
    flex-direction: row

    &--active
      background: linear-gradient(91.9deg, #BDBDBD 2.61%, #A2A2A2 49.06%, #E7E7E7 73.1%, #8B8B8B 97.74%)
      margin-left: -8px

  &__icon
    padding: 0 9px

    &--collapsed
      transform: rotate(-90deg)

  &__title
    font-weight: 700

  &__content
    height: auto
    opacity: 1
    transition: transform 2s ease-in

.sidebar__collapse__header--acitve
  background: linear-gradient(91.9deg, #BDBDBD 2.61%, #A2A2A2 49.06%, #E7E7E7 73.1%, #8B8B8B 97.74%)
  margin-left: -8px
  margin-right: 8px
  +rwd-md
    margin-right: 12px

.visiable
  visibility: hidden

.home
  &__header
    &--active
      background: none
  .sidebar__collapse__header--acitve
    margin-left: 0px
    margin-right: 0px
  .sidebar
    &__collapse
      &__content
        padding-left: 34px
      &__header--acitve
        background: none
</style>
